<template>
  <div class="inline">
    <div
      class="inline"
      :class="metricClass"
      v-if="!metric.format || metric.format.name !== 'html'"
    >
      {{ display }}

      <Stars
        v-if="metric.format && metric.format.name === 'stars'"
        :number="vehicle[metric.col]"
      />
      <YesNoOptional
        v-if="metric.format && metric.format.name === 'yesNoOptional'"
        :expr="vehicle[metric.col]"
      />
      <ChargingThermometer
        class="charging-thermometer"
        v-if="metric.format && metric.format.name === 'chargingThermometer'"
        :vehicle="vehicle"
        :location="location"
      />
      <MobileCompatibility
        v-if="metric.format && metric.format.name === 'mobileCompatibility'"
        :vehicle="vehicle"
      />
    </div>
    <div
      class="inline"
      v-if="metric.format && metric.format.name === 'html'"
      v-html="display"
    ></div>
  </div>
</template>

<script>
import common from "Mixins/common";
const Stars = () => import("Components/Stars");
const YesNoOptional = () => import("Components/YesNoOptional");
const ChargingThermometer = () => import("Components/ChargingThermometer");
const MobileCompatibility = () => import("Components/MobileCompatibility");

export default {
  props: { metric: Object, vehicle: Object },
  computed: {
    metricClass() {
      return this.metric.format?.class;
    },
    display() {
      let vehicle = this.vehicle;
      let metric = this.metric;

      let value = undefined;
      if (metric.col) {
        value = vehicle[metric.col];
      } else {
        value = metric.calc(vehicle, this.location);
      }
      if (!metric.ignoreEmpty && (value === undefined || value === "" || value === null))
        return metric.defaultValue || "Unknown";

      let display = value;
      switch (metric.format?.name) {
        case "currency":
          display = this.toCurrency(value, metric.format.decimals);
          break;
        case "number":
          display = this.toNumber(value);
          break;
        case "stars":
        case "yesNoOptional":
        case "chargingThermometer":
          return "";
        default:
          break;
      }
      if (metric.prefix) display = `${metric.suffix}${display}`;
      if (metric.suffix) display = `${display}${metric.suffix}`;

      return display;
    },
    location() {
      return this.$store.state.locationInfo
    }
  },
  components: {
    Stars,
    YesNoOptional,
    ChargingThermometer,
    MobileCompatibility,
  },
  mixins: [common],
};
</script>

<style lang="scss" scoped>
</style>